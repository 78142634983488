import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Accordion, Button, FloatingLabel, Form } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { MdExpandMore } from 'react-icons/md';
import { BsCaretDown } from 'react-icons/bs';
import { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const BlockFields = ({ block, setHandoff, handoff, disabled }) => {

    function CustomToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        let isCurrentEventKey = activeEventKey === eventKey;
        if (Array.isArray(activeEventKey)) {
            isCurrentEventKey = activeEventKey.includes(eventKey);
        }

        return (
          <div className={`header-and-handle ${isCurrentEventKey ? 'expanded' : 'collapsed'}`} onClick={decoratedOnClick}>
            {children}
          </div>
        );
      }

    return (
        <>
            <DragDropContext onDragEnd={result => {
                if (!result.destination) return;
                const values = [...block.fields];
                const [reorderedItem] = values.splice(result.source.index, 1);
                values.splice(result.destination.index, 0, reorderedItem);
                block.fields = values;
                setHandoff({ ...handoff, blocks: handoff.blocks });
                }}>
                <Droppable droppableId="blcokFieldsDroppable">
                    {(provided, snapshot) => (
                        <Accordion ref={provided.innerRef} {...provided.droppableProps} alwaysOpen>
                            {block.fields && block.fields.map((field, fieldIndex) => (
                                <Draggable key={fieldIndex} draggableId={String(fieldIndex)} index={fieldIndex}>
                                    {(provided, snapshot) => (
                                        <Accordion.Item 
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                            eventKey={String(fieldIndex)}>
                                            <CustomToggle eventKey={String(fieldIndex)}>
                                                <div className="accordion-header">
                                                    {field.name || 'Field name'}
                                                    <div className="accordian-caret">
                                                        <BsCaretDown />
                                                    </div>
                                                </div>
                                                <div className={`field-handle ${snapshot.isDragging ? 'dragging' : ''}`} {...provided.dragHandleProps}>
                                                    <RxDragHandleDots2 />
                                                </div>
                                            </CustomToggle>
                                            <Accordion.Body eventKey={String(fieldIndex)}>
                                                <Form.Group className="mb-2">
                                                    <FloatingLabel label="Field name" className="mb-2">
                                                        <Form.Control type="text" placeholder="Enter field name" value={field.name} onChange={ e => { field.name = e.target.value; setHandoff({ ...handoff, blocks: handoff.blocks }); } } />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Field content" className="mb-2">
                                                        <Form.Control type="text" placeholder="Enter field content" value={field.content} onChange={ e => { field.content = e.target.value; setHandoff({ ...handoff, blocks: handoff.blocks }); } } />
                                                    </FloatingLabel>
                                                    <FloatingLabel label="Field type" className="mb-2">
                                                        <Form.Select aria-label="Field type" value={field.type} onChange={ e => { field.type = e.target.value; setHandoff({ ...handoff, blocks: handoff.blocks }); } }>
                                                            <option value="text">Text</option>
                                                            <option value="password">Password</option>
                                                            <option value="markdown">Markdown</option>
                                                            <option value="file">File</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <Button variant="outline-danger" disabled={disabled} onClick={() => { block.fields.splice(fieldIndex, 1); setHandoff({ ...handoff, blocks: handoff.blocks }); }}>
                                                        Delete field
                                                    </Button>
                                                </Form.Group>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Accordion>
                    )}
                </Droppable>
            </DragDropContext>
            <Button variant="outline-secondary" disabled={disabled} className="mt-3" onClick={() => { block.fields.push({ name: '', content: '' }); setHandoff({ ...handoff, blocks: handoff.blocks }); }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FaPlus />
                    <span style={{ marginLeft: "8px" }}>Add field</span>
                </div>
            </Button>
        </>
    );
};

export default BlockFields;