import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Placeholder, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
import { FaPlus } from "react-icons/fa";
import { BsPersonFill, BsPerson, BsTrashFill } from "react-icons/bs";
import { IoEllipsisVertical } from "react-icons/io5";

import { MdPersonOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { useToast } from '../../context/ToastContext/ToastContext';
import axios from "../../utils/axios";
import DeleteHandoffModal from "./DeleteHandoffModal";

const HandoffsPage = () => {
    const [handoffs, setHandoffs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [handoffToDelete, setHandoffToDelete] = useState(null);
    const [placeholders, setPlaceholders] = useState(3);
    const navigate = useNavigate();
    const { addToast } = useToast();

    const getAllHandoffs = async () => {
        setIsLoading(true);
        try {
            const responsePromise = axios.get("/handoff/all");
            const delayPromise = new Promise((resolve) =>
                setTimeout(resolve, 1000)
            );

            const [response] = await Promise.all([
                responsePromise,
                delayPromise,
            ]);

            if (response.status === 200) {
                setHandoffs(response.data);
            } else {
                addToast({
                    title: "Handoffs failed to load.",
                    body: response.data.msg,
                    variant: "danger",
                    closeButton: true,
                    autoClose: false,
                });
            }
        } catch (err) {
            console.error(err);
            alert("Failed to get handoffs. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getAllHandoffs();
    }, []);

    const HandoffCardMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className="text-secondary handoff-card-menu-trigger"
        >
          {children}
        </a>
    ));
      
    const HandoffCardMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
          return (
            <div
              ref={ref}
              style={style}
              className={className}
              aria-labelledby={labeledBy}
            >
              {children}
            </div>
          );
        },
    );

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1>Handoffs ({ isLoading ? placeholders : handoffs.length })</h1>
                    <Link className="btn btn-primary" to="/handoffs/new">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FaPlus />
                            <span style={{ marginLeft: "8px" }}>New</span>
                        </div>
                    </Link>
                </div>
                <Row>
                    {isLoading
                        ? Array.from({ length: placeholders }, (_, i) => (
                            <Col sm={12} md={6} lg={4} key={i}>
                                <Card
                                    className="py-3 px-3"
                                    style={{
                                        width: "100%",
                                        marginBottom: "24px",
                                        cursor: "pointer",
                                        height: "170px",
                                        opacity: 0.5,
                                    }}
                                >
                                    <Card.Body
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Placeholder
                                            as={Card.Title}
                                            animation="wave"
                                        >
                                            <Placeholder xs={8} style={{ borderRadius: '6px' }} />
                                        </Placeholder>
                                        <Placeholder
                                            as={Card.Text}
                                            animation="wave"
                                            style={{ marginTop: "auto" }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Placeholder xs={6} style={{ borderRadius: '6px' }} />
                                            <Placeholder xs={1} style={{ borderRadius: '6px' }} />
                                            </div>
                                        </Placeholder>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                        : handoffs.map((handoff) => (
                            <Col sm={12} md={6} lg={4} key={handoff._id}>
                                <Card
                                    className="handoff-card py-3 px-3"
                                    style={{
                                        width: "100%",
                                        marginBottom: "24px",
                                        cursor: "pointer",
                                        height: "170px",
                                    }}
                                    onClick={(e) =>
                                        // Navigate to handoff page if click not on dropdown menu
                                        !e.target.closest(".dropdown") &&
                                        navigate(`/handoffs/${handoff._id}/edit`)
                                    }
                                >
                                    <Card.Body
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Card.Title style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span>{handoff.title}</span>
                                            <Dropdown style={{ fontSize: "16px" }}>
                                                <Dropdown.Toggle as={HandoffCardMenuToggle} id="dropdown-custom-components">
                                                    <IoEllipsisVertical style={{ fontSize: "1.4em", verticalAlign: "top" }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu as={HandoffCardMenu}>
                                                    <Dropdown.Item eventKey="1" className="d-flex align-items-center" onClick={(e) => { e.stopPropagation(); setHandoffToDelete(handoff); }}>
                                                        <BsTrashFill />
                                                        <div style={{ marginLeft: "8px" }}>Delete handoff</div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Card.Title>
                                        <Card.Text style={{ marginTop: "auto" }} className="d-flex justify-content-between">
                                            <small className="text-muted">
                                                Contains{" "}
                                                <strong>
                                                    {handoff.blocksCount || 0}
                                                </strong>{" "}
                                                encrypted blocks
                                            </small>
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        {`Shared with ${handoff.owners.length + handoff.editors.length + handoff.viewers.length - 1 } people`}
                                                    </Tooltip>
                                                }
                                                >
                                                    <small className="text-muted" style={{ display: "flex", alignItems: "center" }}>
                                                        { handoff.owners.length + handoff.editors.length + handoff.viewers.length - 1 }
                                                        <MdPersonOutline style={{ fontSize: "1.2em", marginLeft: "4px" }} />
                                                    </small>
                                                </OverlayTrigger>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Layout>
            <DeleteHandoffModal getAllHandoffs={getAllHandoffs} handoffToDelete={handoffToDelete} setHandoffToDelete={setHandoffToDelete} />
            <Outlet />
        </>
    );
};

export default HandoffsPage;
