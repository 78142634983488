import React from "react";
import { Container } from "react-bootstrap";
import DashboardNav from "../DashboardNav/DashboardNav";

const Layout = ({ children, mainClasses = 'py-3 py-lg-5', preMain }) => {
    return (
        <div>
            <header>
                <DashboardNav />
            </header>
            { preMain }
            <main className={ mainClasses }>
                <Container>{children}</Container>
            </main>
            {/* <footer>
                <Container>
                    <p>©2023 SecureHandoff. All rights reserved.</p>
                </Container>
            </footer> */}
        </div>
    );
};

export default Layout;
