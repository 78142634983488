import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../context/ToastContext/ToastContext';
import axios from "../../utils/axios";

const NewHandoffModal = () => {
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const { addToast } = useToast();

    const handleClose = () => {
        navigate('/handoffs'); // This will navigate back in the history stack, effectively closing the modal.
    };

    const handleSaveHandoff = async (e) => {
        e.preventDefault();
        try {
            setIsSaving(true);
            const response = await axios.post("/handoff", { title });
            console.log('response: ', response);
            if (response.status === 201) {
                // redirect looks like: /handoffs/1234567890/edit
                navigate(response.data.redirect);
                addToast({
                    title: "New handoff created.",
                    body: response.data.msg,
                });
            } else {
                alert(response.data.msg);
            }
            setIsSaving(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal onHide={handleClose} show={true}>
            <Modal.Header closeButton>
                <Modal.Title>Create Handoff</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSaveHandoff} disabled={isSaving}>
                    {isSaving ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="visually-hidden">Loading...</span>
                            <span className="ms-2">Saving...</span>
                        </>
                    ) : (
                        "Save"
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewHandoffModal;
