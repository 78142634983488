import React, { useState } from 'react';
import { Button, Form, Card, Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import VerticalLogo from '../../assets/vertical-logo.svg';
import { useAuth } from "../../context/AuthContext/AuthContext";

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setAuthInfo } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (email.trim().length === 0 || password.trim().length === 0) {
            alert("Email and password cannot be empty.");
            return;
        }
    
        setIsLoading(true); // start loading
    
        const delayPromise = new Promise(resolve => setTimeout(resolve, 1000));
        const requestPromise = axios.post('/auth/login', {
            email: email,
            password: password,
        });
    
        try {
            const response = await requestPromise;
            
            if (response.status === 200) {
                await delayPromise; // If the request finishes before the delay, wait for the delay
                setAuthInfo();
                navigate('/handoffs');
            } else {
                alert(response.msg);
                await delayPromise; // If the request finishes before the delay, wait for the delay
                setIsLoading(false); // stop loading
            }
        } catch (err) {
            console.error(err);
            alert('Login failed. Please try again.');
            await delayPromise; // If the request finishes before the delay, wait for the delay
            setIsLoading(false); // stop loading
        }
    };
    

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            
            <Card style={{ width: '100%', maxWidth: '500px' }}>
                <div className="d-flex justify-content-center mt-4">
                    <img src={VerticalLogo} alt="SecureHandoff Logo" style={{ width: '220px'}} />
                </div>
                <Card.Body>
                    <Form onSubmit={handleLogin}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Password" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)}
                                required />
                        </Form.Group>
                        <Button className="mt-3 w-100" variant="primary" size="lg" type="submit" disabled={isLoading}>
                            {isLoading ? 
                                <Spinner animation="border" size="sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> : 'Log In'}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default LoginPage;
