import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Layout from "../../components/Layout/Layout";
import axios from "../../utils/axios";
import { FaPlus } from "react-icons/fa";

import HandoffPageNav from "./HandoffPageNav";
import Block from "./Block";

const EditHandoffPage = () => {
    const [title, setTitle] = useState("Untitled Handoff");
    const [savingDisabled, setSavingDisabled] = useState(true);
    const [changeCount, setChangeCount] = useState(0);

    const [handoff, setHandoff] = useState();
    const [lastSavedHandoff, setLastSavedHandoff] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [isSaving, setIsSaving] = useState(false);

    const { handoffId } = useParams();

    const getHandoff = async () => {
        setIsLoading(true);
        try {
            const responsePromise = axios.get(`/handoff/${handoffId}`);
            const delayPromise = new Promise((resolve) =>
                setTimeout(resolve, 1000)
            );

            const [response] = await Promise.all([
                responsePromise,
                delayPromise,
            ]);

            if (response.status === 200) {
                setHandoff(JSON.parse(JSON.stringify(response.data)));
                setLastSavedHandoff(JSON.parse(JSON.stringify(response.data)));

                setTitle(response.data.title);
            } else {
                alert(response.msg);
            }
        } catch (err) {
            console.error(err);
            alert("Failed to get handoff. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getHandoff();
    }, []);

    const handleSaveHandoff = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        setSavingDisabled(true);
        try {
            console.log('Saving Handoff: ', handoff);
            const response = await axios.put(`/handoff/${handoffId}`, {
                title: handoff.title,
                blocks: handoff.blocks,
            });

            if (response.status === 200) {
                setLastSavedHandoff(JSON.parse(JSON.stringify(handoff)));
            } else {
                setSavingDisabled(false);
                alert(response.msg);
            }
        } catch (err) {
            console.error(err);
            setSavingDisabled(false);
            alert("Failed to save handoff. Please try again.");
        }
    };

    useEffect(() => {
        console.log('handoff: ', handoff);
        console.log('lastSavedHandoff: ', lastSavedHandoff);
        // If the handoff is not the same as the last saved handoff, enable saving
        if (JSON.stringify(handoff) !== JSON.stringify(lastSavedHandoff)) {
            setSavingDisabled(false);

            console.log('Saving is enabled')

            // Calculate the number of changes
            // let count = 0;
            // for (let i = 0; i < handoff.blocks.length; i++) {
            //     if (handoff.blocks !== lastSavedHandoff.blocks[i]) {
            //         count++;
            //     }
            // }
            // setChangeCount(count);
        } else {
            setIsSaving(false);
            setSavingDisabled(true);
            setChangeCount(0);
        }
    }, [handoff, lastSavedHandoff]);

    const handleOnDragEnd = result => {
        if (!result.destination) return;
        const values = [...handoff.blocks];
        const [reorderedItem] = values.splice(result.source.index, 1);
        values.splice(result.destination.index, 0, reorderedItem);
        setHandoff({ ...handoff, blocks: values });
    };

    return (
        <Layout preMain={ <HandoffPageNav isLoading={isLoading} isSaving={isSaving} setHandoff={setHandoff} handoff={handoff} setTitle={setTitle} title={title} savingDisabled={savingDisabled} handleSaveHandoff={handleSaveHandoff} changeCount={changeCount} /> }>
            <div className="container">
                <div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="blocks">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {handoff &&
                                                handoff.blocks &&
                                                handoff.blocks.map((block, index) => (
                                                    <Block key={index} block={block} index={index} setHandoff={setHandoff} handoff={handoff} />
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            <div className="text-center">
                                <Button variant="primary" className="mt-3" onClick={() => { handoff.blocks.push({ order: handoff.blocks.length, name: '', fields: [] }); setHandoff({ ...handoff, blocks: handoff.blocks }); }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <FaPlus />
                                        <span style={{ marginLeft: "8px" }}>Add block</span>
                                    </div>
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default EditHandoffPage;
