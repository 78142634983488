import React, { createContext, useContext, useState } from 'react';
import { Toast } from 'react-bootstrap';

export const ToastContext = createContext();

/*
  addToast({
      title: "Handoffs failed to load.",
      body: response.data.msg,
      variant: "danger",
      closeButton: true,
      autoClose: false,
  });
*/

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = ({ title, body, closeButton = true, autoClose = true, variant = 'primary' }) => {
    setToasts([...toasts, { title, body, closeButton, autoClose, variant, id: new Date().getTime() }]);
  };

  const removeToast = (id) => {
    setToasts(toasts.filter(toast => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        {toasts.map((toast) => (
          <Toast key={toast.id} onClose={() => removeToast(toast.id)} autohide={toast.autoClose} delay={3000} bg={toast.variant}>
            <Toast.Header closeButton={toast.closeButton}>
              <strong className="me-auto">{toast.title}</strong>
            </Toast.Header>
            <Toast.Body>{toast.body}</Toast.Body>
          </Toast>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) throw new Error("useToast must be used within a ToastProvider");

  return context;
};
