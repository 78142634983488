
import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Button, Placeholder, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaSave, FaCheck } from "react-icons/fa";
import { FiEdit2, FiCheck } from "react-icons/fi";
import { GrCheckmark } from "react-icons/gr";
import { useState } from "react";

const HandoffPageNav= ({ isLoading, handoff, setHandoff, changeCount, handleSaveHandoff, savingDisabled, isSaving }) => {
    const [isEditingTitle, setIsEditingTitle] = useState(false);

    const handleEditTitleClick = (e) => {
        e.preventDefault();
        setIsEditingTitle(true);
        // Focus on contentEditable span
        const titleSpan = document.querySelector(".editiable-title");
        titleSpan.contentEditable = true;
        titleSpan.focus();

        // Select all text in contentEditable span
        // const range = document.createRange();
        // range.selectNodeContents(titleSpan);
        // const selection = window.getSelection();
        // selection.removeAllRanges();
        // selection.addRange(range);
    };

    const handleSaveTitle = (e) => {
        e.preventDefault();
        setIsEditingTitle(false);
        // Blur contentEditable span
        const titleSpan = document.querySelector(".editiable-title");
        titleSpan.contentEditable = false;
        titleSpan.blur();

        // Update title in handoff object
        let newHandoff = { ...handoff };
        newHandoff.title = titleSpan.innerText || 'Untitled Handoff';
        setHandoff(newHandoff);
    };

    return (
        <Navbar expand="lg" sticky="top" className="bg-white border-bottom">
            <Container>
                {isLoading ? (
                    <Placeholder
                        as={Navbar.Brand}
                        animation="wave"
                        className="text-muted"
                        style={{ width: "200px", height: "45px" }}
                    >
                        <Placeholder xs={8} size="lg" style={{ borderRadius: "4px" }} />
                        {/* <div className="text-muted fw-light fs-6">
                            <Placeholder xs={4} size="sm" style={{ borderRadius: "4px" }} />
                        </div> */}
                    </Placeholder>
                ) : (
                    <Navbar.Brand>
                        <OverlayTrigger
                            overlay={
                                <Tooltip>
                                    {`Rename handoff`}
                                </Tooltip>
                            }
                        >                                            
                            <div
                                className={`handoff-title ${isEditingTitle ? 'editing' : ''}`}
                                style={{ display: "flex", alignItems: "center" }}
                                onClick={ handleEditTitleClick }
                                >
                                <span
                                    className="editiable-title"
                                    contentEditable="false"
                                    onBlur={ handleSaveTitle }
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.target.blur();
                                        }
                                    }}
                                >
                                    { handoff.title }
                                </span>
                                {!isEditingTitle && (
                                    <FiEdit2 className="fs-6" style={{ marginRight: '10px' }} />
                                )}
                            </div>
                        </OverlayTrigger>
                        {/* <div className="text-muted fw-light fs-6">
                            {changeCount > 0 ? (
                                <span>{ changeCount } changes since last save</span>
                            ) : (
                                <span>No changes since last save</span>
                            )}
                        </div> */}
                    </Navbar.Brand>
                )}
                <Button
                    variant="primary"
                    onClick={handleSaveHandoff}
                    disabled={savingDisabled}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {isSaving ? (
                            <>
                                <FaSave />
                                <span style={{ marginLeft: "8px" }}>
                                    Saving...
                                </span>
                            </>
                        ) : (
                            <>
                            <FaSave />
                            <span style={{ marginLeft: "8px" }}>
                                {!savingDisabled ? "Save" : "Saved"}
                            </span>
                            </>
                        )}
                    </div>
                </Button>
            </Container>
        </Navbar>
    );
};

export default HandoffPageNav;