import React, { createContext, useContext, useState, useEffect } from "react";
import axios from '../../utils/axios';

const AuthContext = createContext();
const AuthCheckContext = createContext(() => {});

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldCheckAuth, setShouldCheckAuth] = useState(false);

  useEffect(() => {
    if (!shouldCheckAuth) return;

    const checkAuth = async () => {
      try {
        const response = await axios.get('/auth/check');
        console.log('Response:', response);
        setIsLoading(false);
        setIsAuthenticated(true);
      } catch (err) {
        console.log('Error:', err);
        setIsLoading(false);
        setIsAuthenticated(false);
      }
    };
  
    checkAuth();
  }, [shouldCheckAuth]);

  const setAuthInfo = () => {
    setIsAuthenticated(true);
  };

  const clearAuth = async () => {
    try {
      await axios.get('/auth/logout');
      setIsAuthenticated(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthInfo, clearAuth, isLoading }}>
      <AuthCheckContext.Provider value={setShouldCheckAuth}>
        {children}
      </AuthCheckContext.Provider>
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth, AuthCheckContext };
