import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import HandoffsPage from './pages/HandoffsPage/HandoffsPage';
import NewHandoffModal from "./pages/HandoffsPage/NewHandoffModal";
import CreateHandoffsPage from './pages/CreateHandoffsPage/CreateHandoffsPage';
import EditHandoffPage from './pages/EditHandoffPage/EditHandoffPage';
import { AuthProvider } from "./context/AuthContext/AuthContext";
import { ToastProvider } from './context/ToastContext/ToastContext';
import PrivateWrapper from "./components/PrivateWrapper/PrivateWrapper";

const App = () => {
  return (
    <AuthProvider>
      <ToastProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/handoffs/*" element={
              <PrivateWrapper>
                <Routes>
                  <Route path="/" element={<HandoffsPage />}>
                    <Route path="new" element={<NewHandoffModal />} />
                  </Route>
                  <Route path=":handoffId/edit" element={<EditHandoffPage />} />
                  <Route path=":handoffId/preview" element={<CreateHandoffsPage />} />
                </Routes>
              </PrivateWrapper>
            } />
            <Route path="/" element={<LoginPage />} /> {/* Default Route */}
          </Routes>
        </Router>
      </ToastProvider>
    </AuthProvider>
  );
} 

export default App;
