import React, { useEffect, useContext } from 'react';
import { useAuth, AuthCheckContext } from '../../context/AuthContext/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const PrivateWrapper = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth();
  const setShouldCheckAuth = useContext(AuthCheckContext);

  useEffect(() => {
    setShouldCheckAuth(true);

    return () => {
      setShouldCheckAuth(false);
    };
  }, [setShouldCheckAuth]);

  if (isLoading) {
    return (
      <div style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateWrapper;
