import React, { useState, useRef } from 'react';
import { Button, Form, Overlay, Popover } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Layout from '../../components/Layout/Layout';
import axios from '../../utils/axios';
import { FaRegSave, FaPlus } from 'react-icons/fa';

const CreateHandoffsPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const handleInputChange = (index, event) => {
    const values = [...blocks];
    values[index][event.target.name] = event.target.value;
    setBlocks(values);
  };

  const handleAddFields = (type) => {
    const values = [...blocks];
    values.push({ type, content: "", name: "", description: "", order: values.length });
    setBlocks(values);
    setShowPopover(false);
  };

  const handleRemoveFields = index => {
    const values = [...blocks];
    values.splice(index, 1);
    setBlocks(values);
  };

  const handleOnDragEnd = result => {
    if (!result.destination) return;
    const values = [...blocks];
    const [reorderedItem] = values.splice(result.source.index, 1);
    values.splice(result.destination.index, 0, reorderedItem);
    setBlocks(values);
  };

  const addBlockPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Add Block</Popover.Header>
      <Popover.Body>
        <Button variant="outline-primary" className="d-block mb-2" onClick={() => handleAddFields('text')}>Text</Button>
        <Button variant="outline-primary" className="d-block mb-2" onClick={() => handleAddFields('password')}>Password</Button>
        <Button variant="outline-primary" className="d-block mb-2" onClick={() => handleAddFields('markdown')}>Markdown</Button>
        {/* add more types here */}
      </Popover.Body>
    </Popover>
  );

    const handleSaveHandoff = async (e) => {
        e.preventDefault();
        try {
            console.log('data: ', title, description, blocks);
            const response = await axios.post('/handoff', {
                title: title,
                description: description,
                blocks: blocks
            });

            console.log('response: ', response);

            if (response.status === 200) {
                console.log('response: ', response.data);
            } else {
                // Handle error here - show a message to the user or something
            }
        } catch (error) {
            // Handle error here
            console.error("An error occurred while saving the handoff:", error);
        }
    };


  return (
    <Layout>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Create a new handoff</h1>
        <Button variant="primary" onClick={handleSaveHandoff}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaRegSave /> 
                <span style={{ marginLeft: '8px' }}>Save & Encrypt</span>
            </div>
        </Button>
      </div>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control type="text" placeholder="Enter description" onChange={e => setDescription(e.target.value)} />
        </Form.Group>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="blocks">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {blocks.map((block, index) => (
                <div key={block.order}>
                    <Draggable draggableId={String(block.order)} index={index}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...provided.draggableProps.style, padding: "10px", border: "2px dashed #ccc", borderRadius: 'var(--bs-border-radius)', backgroundColor: 'var(--bs-light)', marginBottom: "10px"}}>
                            <Form.Group>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <Form.Label>Type: {block.type}</Form.Label>
                                    <Button variant="outline-secondary" onClick={() => handleRemoveFields(index)}>Remove</Button>
                                </div>
                                <Form.Control className="mb-2" type="text" name="name" placeholder="Enter name (optional)" value={block.name} onChange={event => handleInputChange(index, event)} />
                                <Form.Control className="mb-2" type="text" name="description" placeholder="Enter description (optional)" value={block.description} onChange={event => handleInputChange(index, event)} />
                                <Form.Control className="mb-2" type="text" name="content" placeholder="Enter content" value={block.content} onChange={event => handleInputChange(index, event)} />
                            </Form.Group>
                        </div>
                    )}
                    </Draggable>
                </div>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="d-flex justify-content-center">
            <Button variant="secondary" className="mt-2" ref={target} onClick={() => setShowPopover(!showPopover)}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaPlus /> 
                    <span style={{ marginLeft: '8px' }}>Add Encrypted Block</span>
                </div>
            </Button>
            <Overlay target={target.current} show={showPopover} placement="right">
                {addBlockPopover}
            </Overlay>
        </div>
      </Form>
    </Layout>
  );
};

export default CreateHandoffsPage;
