import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from '../../context/ToastContext/ToastContext';

import axios from "../../utils/axios";

const DeleteHandoffModal = ({ getAllHandoffs, handoffToDelete, setHandoffToDelete }) => {
    const navigate = useNavigate();
    const { handoffId } = useParams();
    const [isDeleting, setIsDeleting] = useState(false);
    const { addToast } = useToast();

    const handleClose = () => {
        setHandoffToDelete(null);
    };

    const handleDeleteHandoff = async (e) => {
        e.preventDefault();
        try {
            setIsDeleting(true);
            const response = await axios.delete(`/handoff/${handoffToDelete._id}`);

            if (response.status === 200) {
                navigate(response.data.redirect);
            } else {
                alert(response.data.msg);
            }

            getAllHandoffs();
            handleClose();
            // addToast("Handoff deleted successfully.", "bg-success text-light");
            setIsDeleting(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal onHide={handleClose} show={handoffToDelete}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Handoff</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p>Are you sure you want to delete this handoff?</p>
                <div className="px-3 py-2 bg-light border rounded d-inline-block mb-3">
                    <strong>{handoffToDelete && handoffToDelete.title}</strong>
                </div>
                <p>This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="danger" onClick={handleDeleteHandoff} disabled={isDeleting}>
                    {isDeleting ? (
                        <>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="visually-hidden">Loading...</span>
                            <span className="ms-2">Deleting...</span>
                        </>
                    ) : (
                        "Permamently Delete"
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteHandoffModal;
