import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001/',
  withCredentials: true,
});

// Response interceptor to delay the response
axiosInstance.interceptors.response.use(
  function (response) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(response), 1000);
    });
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Response interceptor to check if 401 unauthorized error received and redirect to login page with redirect query param
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = `/login?redirect=${window.location.pathname}`;
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
