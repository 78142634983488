
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Row, Col, Form, FloatingLabel, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoEllipsisVertical } from "react-icons/io5";
import { BsTrashFill } from "react-icons/bs";

import { RxDragHandleDots2 } from "react-icons/rx";

import BlockFields from "./BlockFields";

const BlockMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-secondary"
    >
      {children}
    </a>
  ));
  
  const BlockMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          {children}
        </div>
      );
    },
  );

const Block = ({ block, index, setHandoff, handoff }) => {
    return (
        <div className={`draggable-block ${block.disabled ? "disabled" : ""}`} key={block.order}>
            <Draggable draggableId={String(block.order)} index={index}>
                {(provided) => (
                    <div className="card my-3" ref={provided.innerRef} {...provided.draggableProps} style={{ ...provided.draggableProps.style }}>
                        <div className="card-body">
                            <div className="card-title border-bottom pb-3 mb-3">
                                <Row>
                                    <Col xs={8} className="block-title">
                                        <FloatingLabel label="Block name">
                                            <Form.Control type="text" placeholder="Enter block name" value={block.name} onChange={ e => { block.name = e.target.value; setHandoff({ ...handoff, blocks: handoff.blocks }); } } />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs={4} className="d-flex justify-content-end align-items-center block-actions">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip>
                                                        {`Enable or disable this block`}
                                                    </Tooltip>
                                                }
                                            >
                                                <Form.Check type="switch" defaultChecked="checked" className="custom-switch" id={`custom-switch-${block.order}`} onChange={ e => { block.disabled = !e.target.checked; setHandoff({ ...handoff, blocks: handoff.blocks }); } } checked={!block.disabled} />
                                            </OverlayTrigger>
                                            <Dropdown style={{ marginLeft: "16px" }}>
                                                <Dropdown.Toggle as={BlockMenuToggle} id="dropdown-custom-components">
                                                    <IoEllipsisVertical style={{ fontSize: "1.4em", verticalAlign: "top" }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu as={BlockMenu}>
                                                    <Dropdown.Item eventKey="1" className="d-flex align-items-center" onClick={() => { handoff.blocks.splice(index, 1); setHandoff({ ...handoff, blocks: handoff.blocks }); }}>
                                                        <BsTrashFill />
                                                        <div style={{ marginLeft: "8px" }}>Delete block</div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="card-text">
                                <BlockFields block={block} setHandoff={setHandoff} handoff={handoff} disabled={block.disabled} />
                            </div>
                            {/* {JSON.stringify(block)} */}
                        </div>
                        <div className="block-handle" {...provided.dragHandleProps}>
                            <RxDragHandleDots2 />
                        </div>
                    </div>
                )}
            </Draggable>
        </div>
    );

};

export default Block;