import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Navbar, Spinner, Modal } from "react-bootstrap";
import icon from "../../assets/icon.svg";
import logo from "../../assets/horizontal-logo.svg";
import { useAuth } from "../../context/AuthContext/AuthContext";
import { Link } from "react-router-dom";
import { useState } from "react";

function DashboardNav() {
    const { clearAuth } = useAuth();
    const [loggingOut, setLoggingOut] = useState(false);

    const handleLogout = () => {
        setLoggingOut(true);
        clearAuth();
    };

    return (
        <>
            <Navbar expand="lg" sticky="top" className="bg-white border-bottom">
                <Container>
                    <Navbar.Brand as={Link} to="/handoffs">
                        <img
                            src={icon}
                            height="48"
                            className="d-inline-block align-top d-none d-lg-inline"
                            alt="Secure Handoff Icon"
                        />
                        <img
                            src={logo}
                            height="48"
                            className="d-inline-block align-top d-lg-none"
                            alt="Secure Handoff Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/handoffs">
                                Handoffs
                            </Nav.Link>
                            <Nav.Link as={Link} to="/sharing">
                                Sharing
                            </Nav.Link>
                            <Nav.Link as={Link} to="/logs">
                                Security Logs
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link as={Link} to="/account">
                                Account
                            </Nav.Link>
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Modal show={loggingOut} centered>
                <Modal.Body>
                    <div className="text-center">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Spinner animation="border" role="status" />
                            <span className="fs-3" style={{ marginLeft: "16px" }}>Logging out...</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DashboardNav;
